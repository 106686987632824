.cstm-popup .top-aligned {
  padding: 0;
  overflow-y: initial;
}

.cstm-popup .right-aligned {
  padding-top: 20px;
  padding-left: 0;
}

.cstm-popup .bottom-aligned {
  padding: 0;
  overflow-y: initial;
}

.cstm-popup .top-aligned img,
.cstm-popup .right-aligned img,
.cstm-popup .bottom-aligned img {
  width: 100%;
  margin: 0 auto;
}

/* custom item type name */
.custom-item-type-name {
  flex: 2;
}
.react-select-container {
  flex: 1;
}
.react-select-container .css-13cymwt-control, .css-t3ipsp-control {
  height: 56px;
  min-height: 56px;
  border-color: rgba(145, 158, 171, 0.32) !important;
  border-radius: 8px;
}
.react-select-container .css-1fdsijx-ValueContainer {
  padding-left: 14px;
  padding-right: 14px;
}
.react-select-container .css-1dimb5e-singleValue svg {
  width: 30px !important;
}
.react-select-container .css-1nmdiq5-menu {
  border-color: rgba(145, 158, 171, 0.32) !important;
  border-radius: 8px;
  overflow: hidden;
  z-index: 111;
}

@media (max-width: 767px) {
  .cstm-popup .only-for-right {
    display: grid;
    grid-template-columns: 1fr 150px;
  }
}

@media (min-width: 768px) {
  .cstm-popup .only-for-right {
    display: grid;
    grid-template-columns: 1fr 200px;
  }

  .cstm-popup .right-aligned {
    padding-top: 20px;
    padding-left: 0;
  }
}
/* Custom css for react-phone-input-2 npm */
.custom-style .special-label {
  color: #909eab;
}
.custom-style:has(input:focus) .special-label {
  color: #01ab55;
}
.custom-style input:hover {
  border-color: #000 !important;
  border-width: 1px;
}
.custom-style input:focus {
  border-color: #01ab55 !important;
  border-width: 2px;
  box-shadow: none !important;
}
